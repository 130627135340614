(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('kendo.core.js'), require('kendo.draganddrop.js'), require('kendo.icons.js')) :
    typeof define === 'function' && define.amd ? define(['kendo.core', 'kendo.draganddrop', 'kendo.icons'], factory) :
    (global = typeof globalThis !== 'undefined' ? globalThis : global || self, (global.kendoreorderable = global.kendoreorderable || {}, global.kendoreorderable.js = factory()));
})(this, (function () {
    var __meta__ = {
        id: "reorderable",
        name: "Reorderable",
        category: "framework",
        depends: [ "core", "draganddrop", "icons" ],
        advanced: true
    };

    (function($, undefined$1) {
        var kendo = window.kendo,
            outerWidth = kendo._outerWidth,
            outerHeight = kendo._outerHeight,
            getOffset = kendo.getOffset,
            Widget = kendo.ui.Widget,
            CHANGE = "change",
            KREORDERABLE = "k-reorderable";

        var Reorderable = Widget.extend({
            init: function(element, options) {
                var that = this;

                Widget.fn.init.call(that, element, options);

                element = that.element.addClass(KREORDERABLE);
                options = that.options;

                that._initDraggable();

                if (!that.options.dropFilter) {
                    that.options.dropFilter = that.draggable.options.filter;
                }

                that.reorderDropCue = that.options.reorderDropCue;

                element.find(options.dropFilter).kendoDropTarget({
                    group: that.draggable.options.group,
                    dragenter: function(e) {
                        var externalDraggableInstance = that._externalDraggable(e);

                        if (!that._draggable && !externalDraggableInstance) {
                            return;
                        }

                        if (externalDraggableInstance) {
                            that._handleExternalDraggable(externalDraggableInstance);
                        }

                        var dropTarget = this.element, offset;

                        var denied = that._isPartOfSortable(that._draggable) ? (!that._dropTargetAllowed(dropTarget) || that._isLastDraggable()) : false;

                        that.toggleHintClass(e.draggable.hint, denied);

                        if (!denied) {
                            offset = getOffset(dropTarget);
                            var cueOffset = { top: offset.top, left: offset.left };
                            var isHorizontal = options.orientation === "horizontal";

                            if (!options.smartPosition || (options.inSameContainer && !options.inSameContainer({
                                source: dropTarget,
                                target: that._draggable,
                                sourceIndex: that._index(dropTarget),
                                targetIndex: that._index(that._draggable)
                            }))) {
                                that._dropTarget = dropTarget;
                            } else {
                                if (that._index(dropTarget) > that._index(that._draggable) && options.smartPosition) {
                                    cueOffset[isHorizontal ? "left" : "top"] += isHorizontal ? outerWidth(dropTarget) : outerHeight(dropTarget);
                                }
                            }

                            that.reorderDropCue.css({
                                height: outerHeight(dropTarget),
                                top: cueOffset.top,
                                left: cueOffset.left,
                                zIndex: 19000
                            });

                            if (options.positionDropCue) {
                                options.positionDropCue(that.reorderDropCue, dropTarget);
                            }
                            that.reorderDropCue.appendTo(document.body);
                        }
                    },
                    dragleave: function(e) {
                        that._dropTarget = null;
                    },
                    drop: function() {
                        that._dropTarget = null;

                        if (!that._draggable) {
                            return;
                        }
                        var dropTarget = this.element;
                        var draggable = that._draggable;
                        var dropIndex = that._index(dropTarget);

                        var isAfter = that.options.orientation === "horizontal" ?
                            getOffset(that.reorderDropCue).left > getOffset(dropTarget).left :
                            getOffset(that.reorderDropCue).top > getOffset(dropTarget).top;

                        dropIndex = isAfter ? dropIndex + 1 : dropIndex;

                        if (that._dropTargetAllowed(dropTarget) && !that._isLastDraggable() && that._index(draggable) !== dropIndex) {
                            that.trigger(CHANGE, {
                                element: that._draggable,
                                target: dropTarget,
                                oldIndex: that._index(draggable),
                                newIndex: that._index(dropTarget),
                                position: isAfter ? "after" : "before"
                            });
                        }

                        if (that.reorderDropCue) {
                            that.reorderDropCue.remove();
                        }
                    }
                });

                that.draggable.bind([ "dragcancel", "dragend", "dragstart", "drag" ], {
                    dragcancel: that._dragcancel.bind(that),
                    dragend: that._dragend.bind(that),
                    dragstart: that._dragstart.bind(that),
                    drag: that._drag.bind(that)
                });
            },

            options: {
                name: "Reorderable",
                filter: "*",
                orientation: "horizontal",
                deniedIcon: "cancel",
                allowIcon: "plus",
                reorderDropCue: $('<div class="k-reorder-cue"></div></div>'),
                smartPosition: true
            },

            events: [
                CHANGE
            ],

            toggleHintClass: function(hint, denied) {
                var that = this,
                    options = that.options;

                hint = $(hint);

                if (hint.find(".k-drag-status").length > 0) {
                    kendo.ui.icon(hint.find(".k-drag-status").eq(0), { icon: denied ? options.deniedIcon : options.allowIcon });
                }
            },

            _initDraggable: function() {
                let that = this,
                    options = that.options;

                that.draggable = options.draggable || new kendo.ui.Draggable(that.element, {
                    group: kendo.guid() + "-reorderable",
                    autoScroll: true,
                    filter: options.filter,
                    hint: options.hint
                });

                let elementUnderCursorFunc = that.draggable._elementUnderCursor;
                that.draggable._elementUnderCursor = function(e) {
                    let shouldModifyCue = that.reorderDropCue && that.reorderDropCue.is(":visible");

                    if (shouldModifyCue) {
                        that.reorderDropCue.hide();
                    }
                    let element = elementUnderCursorFunc.call(that.draggable, e);
                    if (shouldModifyCue) {
                        that.reorderDropCue.show();
                    }
                    return element;
                };
            },

            _handleExternalDraggable: function(draggable) {
                var that = this;
                var draggableFilter = that.options.dropFilter.trimStart();

                // make direct child selectors compatible with .closest()
                if (draggableFilter && draggableFilter[0] == ">") {
                    draggableFilter = draggableFilter.substring(1);
                }

                that._dragcancelHandler = that._dragcancel.bind(that);
                that._dragendHandler = that._dragend.bind(that);
                that._dragstartHandler = that._dragstart.bind(that);
                that._dragHandler = that._drag.bind(that);

                that._draggable = draggable.currentTarget.closest(draggableFilter);
                that._draggableInstance = draggable;
                that._elements = that.element.find(that.options.dropFilter);

                draggable.bind([ "dragcancel", "dragend", "dragstart", "drag" ], {
                    dragcancel: that._dragcancelHandler,
                    dragend: that._dragendHandler,
                    dragstart: that._dragstartHandler,
                    drag: that._dragHandler
                });
            },

            _dragcancel: function() {
                var that = this;

                if (that._draggableInstance && (that._dragcancelHandler || that._dragendHandler ||
                    that._dragstartHandler || that._dragHandler)) {

                        that._draggableInstance.unbind({
                        dragcancel: that._dragcancelHandler,
                        dragend: that._dragendHandler,
                        dragstart: that._dragstartHandler,
                        drag: that._dragHandler
                    });
                }

                if (that.reorderDropCue) {
                    that.reorderDropCue.remove();
                }

                that._draggable = null;
                that._elements = null;

            },
            _dragend: function() {
                var that = this;

                if (that._draggableInstance && (that._dragcancelHandler || that._dragendHandler ||
                    that._dragstartHandler || that._dragHandler)) {

                        that._draggableInstance.unbind({
                        dragcancel: that._dragcancelHandler,
                        dragend: that._dragendHandler,
                        dragstart: that._dragstartHandler,
                        drag: that._dragHandler
                    });
                }

                if (that.reorderDropCue) {
                    that.reorderDropCue.remove();
                }

                that._draggable = null;
                that._elements = null;
            },
            _dragstart: function(e) {
                var that = this;
                var target = $(e.currentTarget);
                var draggableFilter = that.options.dropFilter.trimStart();

                // make direct child selectors compatible with .closest()
                if (draggableFilter && draggableFilter[0] == ">") {
                    draggableFilter = draggableFilter.substring(1);
                }

                that._draggable = target.is(draggableFilter) ? target : target.closest(draggableFilter);
                that._elements = that.element.find(that.options.dropFilter);
            },
            _drag: function(e) {
                var that = this,
                    dropIndex, sourceIndex, denied,
                    offset = {},
                    target,
                    draggableFilter = that.options.dropFilter.trimStart();

                // make direct child selectors compatible with .closest()
                if (draggableFilter && draggableFilter[0] == ">") {
                    draggableFilter = draggableFilter.substring(1);
                }

                target = $(e.currentTarget).closest(draggableFilter);

                if (!that._dropTarget || (that.options.smartPosition && e.sender.hint.find(".k-drag-status").is(".k-i-cancel,.k-svg-i-cancel"))) {
                    return;
                }

                dropIndex = that._index(that._dropTarget);
                sourceIndex = that._index(target);
                sourceIndex = dropIndex > sourceIndex ? sourceIndex + 1 : sourceIndex;

                if (that.options.orientation === "horizontal") {
                    var dropStartOffset = getOffset(that._dropTarget).left;
                    var width = outerWidth(that._dropTarget);

                    if (e.pageX > dropStartOffset + width / 2) {
                        offset.left = dropStartOffset + width;
                        dropIndex += 1;
                    } else {
                        offset.left = dropStartOffset;
                    }
                } else {
                    var dropStartTop = getOffset(that._dropTarget).top;
                    var height = outerHeight(that._dropTarget);

                    if (e.pageY > dropStartTop + height / 2) {
                        offset.top = dropStartTop + height;
                        dropIndex += 1;
                    } else {
                        offset.top = dropStartTop;
                    }
                }

                that.reorderDropCue.css(offset);

                if (that.options.positionDropCue) {
                    that.options.positionDropCue(that.reorderDropCue, that._dropTarget);
                }

                if (that._isPartOfSortable(target)) {

                    denied = sourceIndex === dropIndex ||
                        (that.options.dragOverContainers && !that.options.dragOverContainers(sourceIndex, dropIndex)) ||
                        e.clickMoveClick && e.currentTarget.hasClass("k-drag-cell") && $(e.elementUnderCursor).closest(".k-drag-cell").length === 0;

                    that.toggleHintClass(e.sender.hint, denied);
                }
            },

            _isPartOfSortable: function(draggable) {
                var that = this;

                return that._elements.index(draggable) >= 0;
            },

            _externalDraggable: function(e) {
                var that = this,
                    options = that.options;

                if (!that._draggable && options.externalDraggable) {
                    return options.externalDraggable(e);
                }

                return null;
            },

            _isLastDraggable: function() {
                var inSameContainer = this.options.inSameContainer,
                    draggable = this._draggable[0],
                    elements = this._elements.get(),
                    found = false,
                    item;

                if (!inSameContainer) {
                    return false;
                }

                while (!found && elements.length > 0) {
                    item = elements.pop();
                    found = draggable !== item && inSameContainer({
                        source: draggable,
                        target: item,
                        sourceIndex: this._index(draggable),
                        targetIndex: this._index(item)
                    });
                }

                return !found;
            },

            _dropTargetAllowed: function(dropTarget) {
                var inSameContainer = this.options.inSameContainer,
                    dragOverContainers = this.options.dragOverContainers,
                    draggable = this._draggable;

                if (draggable[0] === dropTarget[0]) {
                    return false;
                }

                if (!inSameContainer || !dragOverContainers) {
                    return true;
                }

                if (inSameContainer({ source: draggable,
                    target: dropTarget,
                    sourceIndex: this._index(draggable),
                    targetIndex: this._index(dropTarget)
                })) {
                    return true;
                }

                return dragOverContainers(this._index(draggable), this._index(dropTarget));
            },

            _index: function(element) {
                return this._elements.index(element);
            },

            destroy: function() {
               var that = this;


               Widget.fn.destroy.call(that);

               that.element.find(that.options.dropFilter).each(function() {
                   var item = $(this);
                   if (item.data("kendoDropTarget")) {
                       item.data("kendoDropTarget").destroy();
                    }
                });

                if (that.draggable) {
                    that.draggable.destroy();

                    that.draggable.element = that.draggable = null;
                }

                that.reorderDropCue.remove();
                that.elements = that.reorderDropCue = that._elements = that._draggable = null;
           }
        });

        kendo.ui.plugin(Reorderable);

    })(window.kendo.jQuery);
    var kendo$1 = kendo;

    return kendo$1;

}));
